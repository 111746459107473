import React, { useState } from 'react';
import RegisterArtist from 'components/RegisterForm/RegisterArtist'
import RegisterBuyer from 'components/RegisterForm/RegisterBuyer';
import './Register.css'

export default function RegisterPage() {
    const [option, setOption] = useState(false);

    const register = option ?
        <RegisterBuyer />
        :
        <RegisterArtist />

    return (
        <>
        <div className='form-login'>
            <h1 className="register-title">¡Bienvenido!</h1>
            <h4 className="register-subtitle">Antes de empezar, quieres registrarte como:</h4>
            <div className="button-container">
                {/* This will be like a switch to change between registers */}
                <button className="artist-type-button" onClick={() => setOption(false)} >Artista</button>
                <button className="buyer-type-button" onClick={() => setOption(true)}  >Comprador</button>
            </div>
            {register}
            </div>
        </>
    )
}