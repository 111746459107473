import { API_URL } from './settings';
import { getAllArt } from './artService'
function getAllProfiles() {
    return fetch(`${API_URL}/all/get/artists`)
        .then(res => {
            if (!res.ok) throw new Error(res.error);
            return res.json()
        })
        .then(res => (res.data))
}

function searchByTitle(title) {
    if (title) {
        return fetch(`${API_URL}/all/search/art/${title}`)
            .then(res => {
                if (!res.ok) throw new Error(res.error);
                return res.json();
            })
            .then(res => (res.data));
    } else {
        return getAllArt();
    }
}

export {
    getAllProfiles,
    searchByTitle
}