import React, { useEffect, useState } from "react";
import './SearchBar.css';
import { searchByTitle } from 'services/globalService';
import { FaSearch } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function SearchBar() {
    const [busqueda, setBusqueda] = useState('');
    const [visible, setVisible] = useState(false);
    const [pieces, setPieces] = useState([]);

    const handleBusquedaChange = (event) => {
        searchByTitle(event.target.value)
            .then(setPieces);
        setBusqueda(event.target.value);
        setVisible(true);
    };

    const clear = () => {
        setBusqueda('');
        searchByTitle('')
            .then(setPieces);
    }

    useEffect(() => {
        if (!busqueda) {
            setVisible(false);
        }
    }, [busqueda]);

    return (
        <div>
            <div className="searchBox">
                {busqueda.length === 0 ? (
                    <FaSearch />
                ) : (
                    <AiOutlineClose id="clearBtn" onClick={clear} />
                )}
                <input className="searchInput" type="text" id="busqueda" value={busqueda} onChange={handleBusquedaChange} />
            </div>
            {
                visible ? (
                    <div className="searchBar-resultContainer">

                        {pieces ? (
                            pieces.map(element => {
                                return (<div className="searchBar-result">
                                    <LazyLoadImage src={`https://pruebas-api.solidartist.org/imgArt/${element && element.content}`} width="30px" height="30px" alt="NFT_filter_img" loading="lazy" />
                                    <span>{element.title}</span>
                                    <span>{element.sell_price}</span>
                                </div>)
                            })
                        ) : ""}

                    </div>
                ) : ""
            }
        </div>
    );
};
